var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paging"},[_c('span',[_vm._v(" Hiển thị "),_c('b',[_vm._v(_vm._s(_vm.total > 0 ? _vm.filter.offset + 1 : 0)+" - "+_vm._s(_vm.filter.offset + _vm.filter.limit > _vm.total ? _vm.total : _vm.filter.offset + _vm.filter.limit))]),_vm._v(" trên tổng số "),_c('b',[_vm._v(_vm._s(_vm.total))]),_vm._v(" bản ghi ")]),_c('div',{staticClass:"right-side"},[(
            _vm.checkAccessRole(
              _vm.$getConst('MODULE').FundTransfer,
              _vm.$getConst('ACTION').FundTransfer.Export
            )
          )?_c('button',{staticClass:"custom-button-excel",on:{"click":function($event){return _vm.$parent.ExportExcel()}}},[_c('img',{attrs:{"src":require("@/assets/img/excel.svg")}}),_vm._v(" Xuất Excel ")]):_vm._e(),(_vm.filter.offset == 0)?_c('button',[_c('img',{attrs:{"src":require("@/assets/img/prev-de.png")}})]):_vm._e(),(_vm.filter.offset != 0)?_c('button',{on:{"click":function($event){_vm.filter.offset -= _vm.filter.limit;
        _vm.$parent.pageChanged();}}},[_c('img',{attrs:{"src":require("@/assets/img/prev-ac.png")}})]):_vm._e(),(_vm.filter.offset + _vm.filter.limit < _vm.total)?_c('button',{on:{"click":function($event){_vm.filter.offset += _vm.filter.limit;
        _vm.$parent.pageChanged();}}},[_c('img',{attrs:{"src":require("@/assets/img/next-ac.png")}})]):_vm._e(),(_vm.filter.offset + _vm.filter.limit >= _vm.total)?_c('button',[_c('img',{attrs:{"src":require("@/assets/img/next-de.png")}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }