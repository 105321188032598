<template>
  <div>
    <div class="order">
      <div>{{ offset + idx + 1 }}</div>
    </div>
    <div class="col">
      <div class="columns">
        <template v-if="data.Status.Code !== $getConst('FUNDSTATUS').Cancelled &&data.OfficeTransactionId>0">
          <div class="column is-2 custom-w-10 text-align">
            {{ ConvertToDateTime.ConvertUnixToDatetime(data.CreatedDate) }}
          </div>
          <div class="column is-1">{{ data.FundTransferDetail.FullName }}</div>
          <div class="column is-2">
            {{ data.UpdateFullName }}<br />
            {{ data.FundReceiveDetail.ShopName }}
          </div>
          <div class="column is-1 text-align money-tranfer">
            {{ formatCurrency(data.Total) }}
          </div>
          <div class="column is-2 custom-w-12">
            {{ data.TypeMethod }}
          </div>
          <div class="column is-1 custom-w-12" style="color: #7a8798">
            {{ data.Note }}
          </div>
          <div class="column is-1 custom-w-12">{{ data.Code }}</div>
        </template>
        <template v-else>
          <div class="column is-2 del custom-w-10 text-align">
            {{ ConvertToDateTime.ConvertUnixToDatetime(data.CreatedDate) }}
          </div>
          <div class="column is-1 del">
            {{ data.FundTransferDetail.FullName }}
          </div>
          <div class="column is-2 del">
            {{ data.UpdateFullName }}<br />
            {{ data.FundReceiveDetail.ShopName }}
          </div>
          <div class="column is-1 del text-align">
            {{ formatCurrency(data.Total) }}
          </div>
          <div class="column is-2 del custom-w-12">
            {{ data.TypeMethod }}
          </div>
          <div class="column is-1 del custom-w-12" style="color: #7a8798">
            {{ data.Note }}
          </div>
          <div class="column is-1 del custom-w-12">{{ data.Code }}</div>
        </template>
        <div class="column is-1 custom-w-10" v-if="data.OfficeTransactionId>0">
          <span :style="{ color: data.Status.Color }">
            <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
            <b class="pawn-status">{{ data.Status.Name }}
                <p
              v-if="data.Status.Code === $getConst('FUNDSTATUS').Cancelled"
              class="note-cancel"
            >
              <b-tooltip
                :label="data.ReasonCancel"
                type="is-white"
                position="is-bottom"
              >
                - Lý do
              </b-tooltip>
            </p>

            </b>
          </span>
        </div>
            <div class="column is-1 custom-w-10" v-else>
          <span >
            <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
            <b class="pawn-status">Giao dịch lỗi</b>
          </span>
        </div>
        <div
          class="column cursor-pointer alight-center custom-width-cl-final"
          v-if="
            data.Status.Code === $getConst('FUNDSTATUS').Complete ||
            data.Status.Code === $getConst('FUNDSTATUS').WaitConfirm
          "
        >
          <div class="menu-dot">
            <b-dropdown position="is-top-left" append-to-body aria-role="menu">
              <template #trigger>
                <a role="button">
                  <span>
                    <img src="@/assets/img/circledoted.svg" />
                  </span>
                </a>
              </template>
              <span class="dropdown-menu-title">chức năng</span>
              <hr class="dropdown-divider" aria-role="menuitem" />
              <b-dropdown-item
                value="edit_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="onMenuClick()"
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundTransferImages,
                    $getConst('ACTION').FundTransfer.Attactment
                  )
                "
              >
                <span>Bổ sung chứng từ</span>
                <b-icon
                  icon="folder-multiple-image"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
              <b-dropdown-item
                value="transaction_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="printTranfer()"
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundTransfer,
                    $getConst('ACTION').FundTransfer.Print
                  )
                "
              >
                <span>In phiếu</span>
                <b-icon
                  icon="cloud-print-outline"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
              <b-dropdown-item
                value="transaction_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="CreateTran()"
                v-if="
                  (!data.FundTransferDetail.IsSuccess ||
                    data.FundTransferDetail.IsSuccess == null) &&
                  data.FundTransferDetail.Requests != null
                "
              >
                <span>Gửi lại lệnh chuyển quỹ</span>
                <b-icon
                  icon="send-outline"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div :id="'print_' + idx" style="display: none">
      <PrintFundTranfer :item="item" code="PC/HS/08/745" v-if="item!=null" />
    </div>
  </div>
</template>
<script>
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import PrintFundTranfer from "@/modules/fundtransfers/components/PrintFundTranfer.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
export default {
  props: ["data", "idx", "offset"],
  components: { PrintFundTranfer },
  methods: {
    async printTranfer() {
      var _self = this;
      // _self.item = await Service.getCollectPOSId(_self.contact.Id);
      _self.$nextTick(function () {
        _self;
        this.GetById();
        setTimeout(async() =>{_self.$htmlToPaper("print_" + _self.idx);},500);
       
         
      });
    },
    async onMenuClick() {
      let _self = this;
      _self.$emit("GetDetail", _self.data.Id);
    },
    async CreateTran() {
       this.GetById();
       setTimeout(async() =>{this.$emit("CreateFund", this.item);},500);
    },
    async GetById() {
      let _self = this;
      let result = await FundTransferService.Get_GetFundById(_self.data.Id);
      _self.item=result;
    },
  },
  mounted() {

   
  },

  data() {
    return {
      ConvertToDateTime: ConvertToDateTime,
      item:null
    };
  },
};
</script>
<style scoped>
.del {
  text-decoration: line-through;
}
.money-tranfer {
  color: red;
  font-weight: bold;
}
</style>
