<template>
  <div class="paging">
    <span>
      Hiển thị
      <b
        >{{ total > 0 ? filter.offset + 1 : 0 }} -
        {{
          filter.offset + filter.limit > total
            ? total
            : filter.offset + filter.limit
        }}</b
      >
      trên tổng số
      <b>{{ total }}</b> bản ghi
    </span>

    <div class="right-side">
      <button class="custom-button-excel" @click="$parent.ExportExcel()"   v-if="
              checkAccessRole(
                $getConst('MODULE').FundTransfer,
                $getConst('ACTION').FundTransfer.Export
              )
            ">
        <img src="@/assets/img/excel.svg" /> Xuất Excel
      </button>
      <button v-if="filter.offset == 0">
        <img src="@/assets/img/prev-de.png" />
      </button>

      <button
        v-if="filter.offset != 0"
        @click="
          filter.offset -= filter.limit;
          $parent.pageChanged();
        "
      >
        <img src="@/assets/img/prev-ac.png" />
      </button>
      <button
        v-if="filter.offset + filter.limit < total"
        @click="
          filter.offset += filter.limit;
          $parent.pageChanged();
        "
      >
        <img src="@/assets/img/next-ac.png" />
      </button>
      <button v-if="filter.offset + filter.limit >= total">
        <img src="@/assets/img/next-de.png" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["filter", "total", "isShows"],
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.isShow = this.isShows;
  },
  method: {
    async ExportEC() {
      this.$parent.ExportExcel();
    },
  },
};
</script>
<style scoped>
.custom-button-excel {
  text-decoration: underline;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  bottom: 6px;
  right: 10px;
  color: #34a263;
}
</style>
