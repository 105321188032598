var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:'back-cover' +
    (_vm.IsActive ? ' active' : ' deactive') +
    (_vm.DisplayNone ? ' display-none' : '')},[_c('div',{staticClass:"add-pay-form"},[_c('div',{staticClass:"side-form-title"},[_vm._v(" CHUYỂN QUỸ NỘI BỘ "),_c('span',{staticClass:"icon-wrapper",on:{"click":function($event){return _vm.SetActive(false)}}},[_c('b-icon',{attrs:{"icon":"close"}})],1)]),_c('div',{staticClass:"form-content"},[_c('div',{staticClass:"fund-info"},[_c('label',{staticClass:"fund-name custom-name"},[_vm._v(_vm._s(_vm.viralFund))]),_c('br'),_c('label',{staticClass:"fund-value"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.SearchData.TotalFund)))])]),(_vm.SubmitData)?_c('div',{staticClass:"payment-type mt-3"},[_c('div',{class:'radio-wrapper mr-2' +
            (_vm.OuType == _vm.Enum.TRANSFER_TYPE.SameOU ? ' active' : '')},[_c('b-radio',{attrs:{"name":"PaymentType","type":"is-info","native-value":_vm.Enum.TRANSFER_TYPE.SameOU},on:{"input":function($event){return _vm.ChangePaymentType()}},model:{value:(_vm.OuType),callback:function ($$v) {_vm.OuType=$$v},expression:"OuType"}},[_vm._v(" Cùng OU")])],1),_c('div',{class:'radio-wrapper mr-2' +
            (_vm.OuType == _vm.Enum.TRANSFER_TYPE.DifferentOU ? ' active' : '')},[_c('b-radio',{attrs:{"name":"PaymentType","type":"is-info","native-value":_vm.Enum.TRANSFER_TYPE.DifferentOU},on:{"input":function($event){return _vm.ChangePaymentType()}},model:{value:(_vm.OuType),callback:function ($$v) {_vm.OuType=$$v},expression:"OuType"}},[_vm._v(" Khác OU")])],1)]):_vm._e(),_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Loại hình"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Loại hình "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-select',{staticClass:"custom-ip",attrs:{"placeholder":"Chọn","expanded":""},on:{"input":function($event){return _vm.ChangeMethod()}},model:{value:(_vm.SubmitData.CategoryMethods.Code),callback:function ($$v) {_vm.$set(_vm.SubmitData.CategoryMethods, "Code", $$v)},expression:"SubmitData.CategoryMethods.Code"}},_vm._l((_vm.listMethod),function(item){return _c('option',{key:item.Code,domProps:{"value":item.Code}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),0)],1)]}}])})],1),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Chuyển tiền cho"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chuyển tiền cho "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('v-select',{ref:"model",staticClass:"style-chooser-custom",class:{ disabled: _vm.isDisabledTo },attrs:{"placeholder":"PGD Nhận","label":"Name","options":_vm.listPGD,"reduce":function (option) { return option.Code; },"disabled":_vm.isDisabledTo},on:{"input":_vm.ChangeShop},model:{value:(_vm.SubmitData.FundReceiveDetail.ShopCode),callback:function ($$v) {_vm.$set(_vm.SubmitData.FundReceiveDetail, "ShopCode", $$v)},expression:"SubmitData.FundReceiveDetail.ShopCode"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Không có kết quả phù hợp")])])],1)]}}])})],1),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Nhân viên"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nhân viên (Người nhận) "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-select',{staticClass:"custom-ip",attrs:{"placeholder":"Chọn","expanded":"","disabled":_vm.isVisibleTo},on:{"input":function($event){return _vm.ChangeReceiveUser()}},model:{value:(_vm.SubmitData.FundReceiveDetail.UserName),callback:function ($$v) {_vm.$set(_vm.SubmitData.FundReceiveDetail, "UserName", $$v)},expression:"SubmitData.FundReceiveDetail.UserName"}},_vm._l((_vm.lstUser),function(item){return _c('option',{key:item.Id,domProps:{"value":item.UserName}},[_vm._v(" "+_vm._s(item.FullName)+" ")])}),0)],1)]}}])})],1),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Số tiền"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số tiền "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('currency-input',{staticClass:"input input-text-right custom-ip-curency",attrs:{"precision":0,"expanded":"","disabled":_vm.isVisibleTo},model:{value:(_vm.SubmitData.Total),callback:function ($$v) {_vm.$set(_vm.SubmitData, "Total", $$v)},expression:"SubmitData.Total"}})],1)]}}])})],1),(
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.BankToCash ||
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.BankToBank ||
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.BankToWallet ||
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.WalletToBank ||
            _vm.SubmitData.CategoryMethods.Code == _vm.Enum.Method_Receive.GPayToBank
          )?_c('div',{staticClass:"mt-3 columns mb-0"},[_c('div',{staticClass:"column is-6 pb-0 pt-0"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":_vm.viralBankTran},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.viralBankTran,"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.viralBankTran)+" "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"style-chooser-custom custom-ip",attrs:{"placeholder":"Chọn","label":"Value","options":_vm.listBank,"reduce":function (option) { return option.Code; }},on:{"input":_vm.ChangeBankTransfer},model:{value:(_vm.SubmitData.FundTransferDetail.BankCode),callback:function ($$v) {_vm.$set(_vm.SubmitData.FundTransferDetail, "BankCode", $$v)},expression:"SubmitData.FundTransferDetail.BankCode"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Không có kết quả phù hợp")])])],1)]}}],null,false,889938356)})],1),_c('div',{staticClass:"column is-6 pb-0 pt-0"},[_c('b-field',{attrs:{"custom-class":"is-small"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.viralBankBalance)+" ")]},proxy:true}],null,false,1782291574)},[_c('currency-input',{staticClass:"input custom-ip",attrs:{"precision":0,"expanded":"","disabled":true},model:{value:(_vm.SearchData.TotalFund),callback:function ($$v) {_vm.$set(_vm.SearchData, "TotalFund", $$v)},expression:"SearchData.TotalFund"}})],1)],1)]):_vm._e(),(
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.CashToBank ||
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.BankToBank ||
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.BankToWallet ||
            _vm.SubmitData.CategoryMethods.Code ==
              _vm.Enum.Method_Receive.WalletToBank ||
            _vm.SubmitData.CategoryMethods.Code == _vm.Enum.Method_Receive.GPayToBank
          )?_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":_vm.viralBankRev},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-field',{attrs:{"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.viralBankRev},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.viralBankRev)+" "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"style-chooser-custom custom-ip",attrs:{"placeholder":"Chọn","label":"Value","options":_vm.listBank,"reduce":function (option) { return option.Code; },"disabled":_vm.isVisibleTo},on:{"input":_vm.ChangeBankReceive},model:{value:(_vm.SubmitData.FundReceiveDetail.BankCode),callback:function ($$v) {_vm.$set(_vm.SubmitData.FundReceiveDetail, "BankCode", $$v)},expression:"SubmitData.FundReceiveDetail.BankCode"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Không có kết quả phù hợp")])])],1)]}}],null,false,3596754237)})],1):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Nội dung"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-field',{attrs:{"custom-class":"is-small","type":{ 'is-danger': errors[0] },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nội dung "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-input',{staticClass:"custom-ip",attrs:{"placeholder":"Nội dung","type":"textarea","disabled":_vm.isVisibleTo},model:{value:(_vm.SubmitData.Note),callback:function ($$v) {_vm.$set(_vm.SubmitData, "Note", $$v)},expression:"SubmitData.Note"}})],1)]}}])})],1)]),_c('div',{staticClass:"mt-3"},[_c('b-field',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ảnh chứng từ ")]},proxy:true}])},[_c('div',{staticClass:"upload-field custom-bt-upload"},[_c('b-upload',{attrs:{"multiple":"","accept":".png, .jpg, .jpeg"},on:{"input":_vm.uploadFiles},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},[_c('span',{staticClass:"upload-file-title",class:{ 'upload-file-title-active': true }},[_vm._v("Chọn ảnh chứng từ")]),_c('div',{staticClass:"button-upload"},[_c('img',{attrs:{"src":require("@/assets/img/upload.svg")}})])])],1)])],1),_c('div',{staticClass:"mt-3"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:(_vm.options),expression:"options"}],staticClass:"upload-photos"},_vm._l((_vm.SubmitData.ListImage),function(item,index){return _c('FileItem',{key:index,attrs:{"file":item,"idx":index,"IsShow":true},on:{"deleteFile":_vm.deleteFile}})}),1)])],1),_c('div',{staticClass:"button-wrapper"},[_c('b-button',{attrs:{"type":"is-link","disabled":_vm.isDisplayButton},on:{"click":function($event){$event.preventDefault();return _vm.CreateFund.apply(null, arguments)}}},[_vm._v("CHUYỂN QUỸ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }