<template>
  <section>
    <div class="main-content">
      <div class="pawn-detail-content">
        <div class="header-bar-asset-config">
          <img class="back-btn" src="@/assets/img/prev.svg" alt />
          <div class="title-asset-config">CHUYỂN QUỸ NỘI BỘ</div>
          <b-button
            class="button add-pay-op"
            @click="ShowAddForm()"
            v-if="
              checkAccessRole(
                $getConst('MODULE').FundTransfer,
                $getConst('ACTION').FundTransfer.Create
              )
            "
          >
            <b-icon icon="plus-circle-outline"></b-icon>
            <span class="menu-text">Chuyển quỹ nội bộ</span>
          </b-button>
        </div>
        <div class="filter is-gapless columns mar-10" :class="{ customerborder: shopSelected == Enum.OFFICE.HO }">
          <div class="column is-12">
            <div
              class="columns is-gapless dropdown-search-custom"
              
            >
              <b-field class="column is-3">
                <b-datepicker
                  class=""
                  :locale="locale"
                  placeholder="Từ ngày đến ngày"
                  icon-right="calendar-today"
                  :years-range="[-100, 100]"
                  trap-focus
                  v-model="selectDate"
                  @input="GetListFundTransfer()"
                  range
                >
                </b-datepicker>
              </b-field>
              <b-field class="column is-2 custom-w-12">
                <v-select
                  ref="status"
                  placeholder="Trạng thái"
                  class="style-chooser"
                  label="Name"
                  v-model="filter.status"
                  :options="listStatus"
                  :reduce="(option) => option.Code"
                  @input="changeStatus"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
              <b-field class="column is-2">
                <v-select
                  ref="method"
                  placeholder="Hình thức"
                  class="style-chooser"
                  label="Name"
                  v-model="filter.method"
                  :options="listMethod"
                  :reduce="(option) => option.Code"
                  @input="changeMethod"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
              <b-field class="column is-2 custom-w-12">
                <v-select
                  ref="bank"
                  placeholder="Ngân hàng chuyển"
                  class="style-chooser"
                  label="Value"
                  v-model="filter.bankTransfer"
                  :options="listBank"
                  :reduce="(option) => option.Code"
                  @input="changeBankCode"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
              <b-field class="column is-2 custom-w-12">
                <v-select
                  ref="bank"
                  placeholder="Ngân hàng nhận"
                  class="style-chooser"
                  label="Value"
                  v-model="filter.bankCode"
                  :options="listBank"
                  :reduce="(option) => option.Code"
                  @input="changeBankCode"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
              <b-field class="column is-3 custom-w-22">
                <v-select
                  ref="model"
                  placeholder="PGD Nhận"
                  class="style-chooser"
                  label="Name"
                  v-model="filter.shopCode"
                  :options="listShop"
                  :reduce="(option) => option.Code"
                  @input="changeShopCode"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
            </div>
          </div>
        </div>
        <div
          class="filter is-gapless columns"
          :class="{ custombordertop: shopSelected == Enum.OFFICE.HO }"
          v-if="shopSelected == Enum.OFFICE.HO && listShop.length > 0"
        >
          <div class="column is-3">
            <div class="columns is-gapless dropdown-search-custom">
              <b-field class="column is-12">
                <v-select
                  ref="model"
                  placeholder="PGD Chuyển"
                  class="style-chooser"
                  label="Name"
                  v-model="shopTranfer"
                  :options="listShop"
                  :reduce="(option) => option.Code"
                  @input="GetListFundTransfer"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
            </div>
          </div>
        </div>
        <div>
          <PagingCustom :filter="filter" :total="total" />
          <div class="header-table">
            <div class="order">#</div>
            <div class="col">
              <div class="columns">
                <div class="column is-2 custom-w-10 text-align">Ngày</div>
                <div class="column is-1">Người tạo</div>
                <div class="column is-2">Xác nhận</div>
                <div class="column is-1 text-align">Số tiền</div>
                <div class="column is-2 custom-w-12">Hình thức</div>
                <div class="column is-1 custom-w-12">Ghi chú</div>
                <div class="column is-1 custom-w-12">VA Code</div>
                <div class="column is-1 custom-w-10">Trạng thái</div>
                <div class="column is-1 custom-width-cl-final"></div>
              </div>
            </div>
          </div>
          <div class="main-table tbl-bank-transfer-list">
            <div
              class="item-table"
              v-for="(item, idx) in ListTransfers"
              :key="item.Id"
            >
              <ItemFundTransfer
                :idx="idx"
                :data="item"
                :offset="filter.offset"
                @GetDetail="ShowPopupEdit"
                @CreateFund="CreateFund"
              />
            </div>
          </div>
          <PagingItem :filter="filter" :total="total" />
        </div>
      </div>
      <PopupAddFund
        ref="PopupAddFund"
        :listMethod="lstMethodChild"
        :listBank="listBank"
        :listShop="listShop"
        :position="position"
        :isload="isLoad"
        :Virtual="Virtual"
        @ChangeTypeOU="ChangeTypeUO"
        @AddSuccess="GetListFundTransfer()"
      />
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </section>
</template>
<script>
import PopupAddFund from "@/modules/fundtransfers/components/PopupAddFund.vue";
import ItemFundTransfer from "@/modules/fundtransfers/components/ItemFundTransfer.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
//import store from "@/store";
import Enum from "@/helpers/Enum.vue";
import PopupEditImage from "@/modules/fundtransfers/components/PopupEditImage.vue";
import UploadAWS from "@/helpers/UploadAWS.vue";
import moment from "moment";
import PagingCustom from "@/modules/fundtransfers/components/PagingCustom.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
//import Helper from "@/helpers/Helper.vue";
import UploadStatic from "@/helpers/UploadStatic.vue";
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;

export default {
  components: {
    ItemFundTransfer,
    PopupAddFund,
    PagingCustom,
  },
  data() {
    return {
      selectedCode:null,
      listShop: [],
      listBank: [],
      listMethod: [],
      listStatus: [],
      lstMethodChild: [],
      locale: "vi-ve",
      total: 0,
      ListTransfers: [],
      isShowPopup: false,
      fromDate: null,
      toDate: null,
      shopTranfer: null,
      selectDate: [],
      filter: {
        offset: 0,
        limit: 10,
        search: null,
        fromDate: null,
        toDate: null,
        status: null,
        method: null,
        bankCode: null,
        shopCode: null,
        shopCodeTransfer: null,
        bankTransfer: null,
      },
      position: null,
      isLoad: false,
      ListImage: [],
      files: [],
      SMData: {
        IdFund: null,
        ListImage: [],
      },
      isLoading: false,
      lstbank: [],
      Virtual: null,
      shopSelected: null, 
      Enum: Enum,
    };
  },
  mounted() {
    var _self = this;
    _self.checkURL();
    _self.$parent.$refs.info.isDis = false;
    let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
    _self.shopTranfer = shopSelected.Code;
    _self.selectedCode=shopSelected.Code;
    _self.shopSelected = shopSelected.BusinessOfficeCode;
    console.log("_self.shopSelected",_self.shopSelected);
    console.log("Enum.OFFICE.HO",Enum.OFFICE.HO);
    _self.position = Enum.METHOD_TYPE.PGD;
    if (shopSelected.BusinessOfficeCode == Enum.OFFICE.HO) {
      _self.position = Enum.METHOD_TYPE.HO;
    }
    _self.GetListStatusFund();
    _self.GetListMethod();
    _self.GetListBank();
    _self.GetListPGD();

    _self.GetListFundTransfer();
  },
  methods: {
    async GetListFundTransfer() {
      let _self = this;
      if (_self.selectDate[0] != null) {
        _self.filter.fromDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[0]
        );
        _self.filter.toDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[1]
        );
        let cal = ConvertToDateTime.CalcularDate(
          _self.selectDate[1],
          _self.selectDate[0]
        );
        if (cal > 30) {
          ToastHelper.OpenToast(
            "Bạn không thể xem dữ liệu vượt quá 30 ngày",
            "is-warning"
          );
          return;
        }
      }
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.filter.shopCodeTransfer = shopSelected.Code;
        if (
        shopSelected &&
        shopSelected.BusinessOfficeCode != null &&
        shopSelected.BusinessOfficeCode == Enum.OFFICE.HO
      )
        _self.filter.shopCodeTransfer = _self.shopTranfer;
      if (_self.filter.shopCodeTransfer == null) {
        _self.ListTransfers = null;
        return;
      }
      let result = await FundTransferService.Post_ListFund(_self.filter);
      _self.total = result.Total;
      _self.ListTransfers = result.Data;
      _self.isLoad = true;
    },
    pageChanged() {
      let _self = this;
      _self.GetListFundTransfer();
      _self.$router.push({
        name: "ListFundTransfer",
        query: {
          Offset: _self.filter.offset,
          Limit: _self.filter.limit,
          Search: _self.filter.search,
        },
      });
    },
    filterData() {
      let _self = this;
      _self.filter.offset = 0;
      _self.GetListFundTransfer(_self.filter);
    },
    async GetListStatusFund() {
      let _self = this;
      let result = await FundTransferService.Get_ListStatusFund();
      _self.listStatus = result;
    },
    async changeStatus(value) {
      var _self = this;
      _self.filter.status = value;
      _self.filterData();
    },
    async GetListMethod() {
      let _self = this;
      let result = await FundTransferService.Get_ListMethod(_self.position);
      let result2 = await FundTransferService.Get_ListMethodPopup(
        _self.position,
        Enum.TRANSFER_TYPE.SameOU
      );
      _self.listMethod = result;
      _self.lstMethodChild = result2;
    },
    async changeMethod(value) {
      let _self = this;
      _self.filter.method = value;
      _self.filterData();
    },
    async GetListBank() {
      let _self = this;
      let result = await FundTransferService.Get_ListBank();
      let dataBank = result.Data;
      _self.listBank = dataBank;
    },
    async changeBankCode() {
      let _self = this;
      _self.filterData();
    },
    async GetListPGD() {
      let _self = this;
      _self.isLoading = true;
      var result = await FundTransferService.Get_ListPGDNew();
      _self.listShop = result.Data;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let vir = _self.listShop.filter((x) => x.Code == shopSelected.Code);
      console.log("vir", vir);
      if (vir !== null && vir[0].VirtualVpbankAccount != null)
        _self.Virtual = vir[0].VirtualVpbankAccount;
      _self.isLoading = false;
    },
    async changeShopCode() {
      var _self = this;
      _self.filterData();
    },
    async ShowAddForm() {
      var _self = this;
      _self.isload = true;
      _self.$refs.PopupAddFund.SetActive(true);
    },
    async ChangeTypeUO(value) {
      var _self = this;
      var result = await FundTransferService.Get_ListMethodPopup(
        _self.position,
        value
      );
      _self.lstMethodChild = result;
    },
    async ShowPopupEdit(val) {
     
      var result = await FundTransferService.Get_ListImage(val);
      var _self = this;
      console.log("_self.selectedCode",_self.selectedCode);
      console.log("_self.filter.shopTranfer",_self.shopTranfer);
      _self.SMData.IdFund = val;
      _self.ListImage = null;
      _self.ListImage = result;
      _self.SMData.ListImage = result;
      _self.$buefy.modal.open({
        parent: _self,
        component: PopupEditImage,
        hasModalCard: true,
        trapFocus: true,
        props: {
          ListImage: _self.ListImage,
          SMData: _self.SMData,
          IsShow: _self.selectedCode==_self.shopTranfer,
        },
        events: {
          SelectImage: _self.SelectImage,
          uploadFile: _self.uploadFiles,
          deleteFile: _self.deleteFile,
        },
      });
    },
    async SelectImage(image) {
      var _self = this;
      _self.isLoading = true;
      _self.ListImage.push(image);
      _self.isLoading = false;
    },
    async deleteFile(file) {
      var _self = this;
      _self.isLoading = true;
      _self.$buefy.dialog.confirm({
        title: "Xóa ảnh",
        message: Enum.MESSAGE.ConfirmDeleteImage + file.Name + "</b>",
        confirmText: "Đồng ý",
        cancelText: "Hủy",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          var currentItem = _self.ListImage.filter(
            (x) => x.Path == file.Path
          )[0];
          var index = _self.ListImage.indexOf(currentItem);
          if (index > -1) {
            await UploadAWS.deleteFile(file.Path);
            _self.ListImage.splice(index, 1);
            _self.SMData.ListImage = _self.ListImage;
          }
        },
      });
      _self.isLoading = false;
    },
    async uploadFiles(files) {
      let _self = this;
      _self.isLoading = true;
      _self.files = files;
      for (let i = 0; i < _self.files.length; i++) {
        let item = _self.files[i];
        let now = moment();
        let folder = "Images/Transfer";
        let pathRoot = `${now.format("YYYY")}/${now.format("MM")}/${folder}`;
        let fileName = `${now.format("YYYYMMDD")}_${now.format(
          "HHmmss"
        )}_${now.format("X")}_${Math.floor(Math.random() * 0x10000).toString(
          16
        )}`;
        let pathFull = `${pathRoot}/${fileName}.${item.name.slice(
          item.name.lastIndexOf(".") + 1
        )}`;

        await UploadStatic.PutFileS3(
          pathFull,
          item,
          fileName,
        );

        // if (result) {
          let file = {
            Path: amzUrl + pathFull,
            Name: fileName,
            Type: 1,
          };
          _self.ListImage.push(file);
          _self.SMData.ListImage = null;
          _self.SMData.ListImage = _self.ListImage;
        // }
      }
      _self.files = [];
      _self.isLoading = false;
    },
    async ExportExcel() {
      let _self = this;
      let filterModel = _self.filter;
      let result = await FundTransferService.Post_ExportExcelFund(filterModel);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([result]));
      link.setAttribute("download", "danh_sach_chuyen_quy.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    async CreateFund(item) {
      this.isLoading = true;
      let request = JSON.parse(item.FundTransferDetail.Requests);
      let result = await FundTransferService.Post_CreateTran(item.Id, request);
      if (result) {
        ToastHelper.OpenToast("Cập nhật giao dịch thành công!", "is-success");
      } else {
        ToastHelper.OpenToast(
          "Cập nhật giao dịch không thành công!",
          "is-danger"
        );
      }
      this.isLoading = false;
      this.GetListFundTransfer();
      console.log(result);
    },
  },
};
</script>

<style lang="scss">
</style>
