<template>
  <div class="modal-content">
    <div class="modal-main">
      <div class="mt-3" v-if="IsShow">
        <b-field>
          <div class="upload-field">
            <b-upload
              @input="uploadFiles"
              v-model="files"
              multiple
              accept=".png, .jpg, .jpeg"
            >
              <span
                class="upload-file-title"
                :class="{ 'upload-file-title-active': true }"
                >Ảnh đính kèm</span
              >
              <div class="button-upload">
                <img src="@/assets/img/upload.svg" />
              </div>
            </b-upload>
          </div>
        </b-field>
      </div>
      <div class="mt-3">
        <div class="upload-photos">
           <div v-viewer="options" class=" images clearfix">
          <FileItem
            v-for="(item, index) in ListImage"
            :key="index"
            :file="item"
            :idx="index"
            @deleteFile="deleteFile"
             :IsShow="IsShow"
          />
           </div>
        </div>
      </div>
      <div style="padding-top: 5px; text-align: center" v-if="IsShow">
        <b-button label="Đóng" type="is-text" @click="onClose()"> </b-button>
        <b-button
          label="Lưu ảnh"
          @click="onSave()"
          type="is-info"
          class="custom-button"
          :disabled="isDisableSave"
        >
        </b-button>
      </div>
    </div>
      <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
  
</template>
<script>
import FileItem from "@/modules/fundtransfers/components/FileItem.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
export default {
  components: {
    FileItem,
  },
  props: ["ListImage", "SMData","IsShow"],
  data() {
    return {  
      options: {
                toolbar: true,
                url: 'data-source',
            },
      files: [],
      isLoading:false,
      isDisableSave:false
    };
  },
  mounted() {
    this.files = null;
    this.isDisableSave=false;
  },
  methods: {
    async deleteFile(items) {
      this.$emit("deleteFile", items);
    },
    async uploadFiles() {
      let _self = this;
      _self.$emit("uploadFile", _self.files);
      _self.files=null;
    },
    async onSave() {
      let _self = this;
      _self.isLoading=true;
      let result = await FundTransferService.Post_CreateFundImage(_self.SMData);
      _self.isLoading=false;
      _self.isDisableSave=true;
      if (result.Success) {
        ToastHelper.OpenToast(result.Message, "is-success");
      } else {
        ToastHelper.OpenToast(result.Message, "is-danger");
      }
      _self.onClose();
    },
    async onClose() {
      let _self = this;
      _self.isDisableSave=false;
      _self.$emit("close");
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">

</style>
