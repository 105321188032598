<template>
  <section
    :class="
      'back-cover' +
      (IsActive ? ' active' : ' deactive') +
      (DisplayNone ? ' display-none' : '')
    "
  >
    <div class="add-pay-form">
      <div class="side-form-title">
        CHUYỂN QUỸ NỘI BỘ
        <span class="icon-wrapper" @click="SetActive(false)">
          <b-icon icon="close"></b-icon>
        </span>
      </div>
      <div class="form-content">
        <div class="fund-info">
          <label class="fund-name custom-name">{{ viralFund }}</label
          ><br />
          <label class="fund-value">{{
            formatCurrency(SearchData.TotalFund)
          }}</label>
        </div>
        <div class="payment-type mt-3" v-if="SubmitData">
          <div
            :class="
              'radio-wrapper mr-2' +
              (OuType == Enum.TRANSFER_TYPE.SameOU ? ' active' : '')
            "
          >
            <b-radio
              v-model="OuType"
              name="PaymentType"
              type="is-info"
              :native-value="Enum.TRANSFER_TYPE.SameOU"
              @input="ChangePaymentType()"
            >
              Cùng OU</b-radio
            >
          </div>
          <div
            :class="
              'radio-wrapper mr-2' +
              (OuType == Enum.TRANSFER_TYPE.DifferentOU ? ' active' : '')
            "
          >
            <b-radio
              v-model="OuType"
              name="PaymentType"
              type="is-info"
              :native-value="Enum.TRANSFER_TYPE.DifferentOU"
              @input="ChangePaymentType()"
            >
              Khác OU</b-radio
            >
          </div>
        </div>
        <ValidationObserver ref="observer">
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Loại hình"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Loại hình <span class="has-text-danger">*</span>
                </template>
                <b-select
                  placeholder="Chọn"
                  expanded
                  v-model="SubmitData.CategoryMethods.Code"
                  @input="ChangeMethod()"
                  class="custom-ip"
                >
                  <option
                    v-for="item in listMethod"
                    :value="item.Code"
                    :key="item.Code"
                  >
                    {{ item.Name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Chuyển tiền cho"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Chuyển tiền cho <span class="has-text-danger">*</span>
                </template>

                <v-select
                  ref="model"
                  placeholder="PGD Nhận"
                  class="style-chooser-custom"
                  label="Name"
                  v-model="SubmitData.FundReceiveDetail.ShopCode"
                  :options="listPGD"
                  :reduce="(option) => option.Code"
                  @input="ChangeShop"
                  :disabled="isDisabledTo"
                  :class="{ disabled: isDisabledTo }"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>

                <!-- <b-select
                  placeholder="Chọn"
                  expanded
                  v-model="SubmitData.ShopCodeReceive"
                  @input="ChangeShop()"
                  :disabled="isDisabledTo"
                >
                  <option
                    v-for="item in listShop"
                    :value="item.Code"
                    :key="item.Code"
                  >
                    {{ item.Name }}
                  </option>
                </b-select> -->
              </b-field>
            </ValidationProvider>
          </div>
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Nhân viên"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Nhân viên (Người nhận) <span class="has-text-danger">*</span>
                </template>
                <b-select
                  placeholder="Chọn"
                  expanded
                  v-model="SubmitData.FundReceiveDetail.UserName"
                  @input="ChangeReceiveUser()"
                  :disabled="isVisibleTo"
                  class="custom-ip"
                >
                  <option
                    v-for="item in lstUser"
                    :value="item.UserName"
                    :key="item.Id"
                  >
                    {{ item.FullName }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Số tiền"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Số tiền <span class="has-text-danger">*</span>
                </template>
                <currency-input
                  class="input input-text-right custom-ip-curency"
                  :precision="0"
                  expanded
                  v-model="SubmitData.Total"
                  :disabled="isVisibleTo"
                />
              </b-field>
            </ValidationProvider>
          </div>
          <div
            class="mt-3 columns mb-0"
            v-if="
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.BankToCash ||
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.BankToBank ||
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.BankToWallet ||
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.WalletToBank ||
              SubmitData.CategoryMethods.Code == Enum.Method_Receive.GPayToBank
            "
          >
            <div class="column is-6 pb-0 pt-0">
              <ValidationProvider
                :rules="{ required: true }"
                :name="viralBankTran"
                v-slot="{ errors }"
              >
                <b-field
                  :label="viralBankTran"
                  custom-class="is-small"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <template #label>
                    {{ viralBankTran }}
                    <span class="has-text-danger">*</span>
                  </template>
                  <v-select
                    placeholder="Chọn"
                    class="style-chooser-custom custom-ip"
                    label="Value"
                    v-model="SubmitData.FundTransferDetail.BankCode"
                    :options="listBank"
                    :reduce="(option) => option.Code"
                    @input="ChangeBankTransfer"
                  >
                    <div slot="no-options">Không có kết quả phù hợp</div>
                  </v-select>
                  <!-- <b-select
                    placeholder="Chọn"
                    expanded
                    v-model="SubmitData.FundTransferDetail.BankCode"
                    @input="ChangeBankTransfer()"
                  >
                    <option
                      v-for="item in listBank"
                      :value="item.Code"
                      :key="item.Id"
                    >
                      {{ item.Value }}
                    </option>
                  </b-select> -->
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-6 pb-0 pt-0">
              <b-field custom-class="is-small">
                <template #label> {{ viralBankBalance }} </template>
                <currency-input
                  class="input custom-ip"
                  :precision="0"
                  expanded
                  v-model="SearchData.TotalFund"
                  :disabled="true"
                />
              </b-field>
            </div>
          </div>
          <div
            class="mt-3"
            v-if="
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.CashToBank ||
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.BankToBank ||
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.BankToWallet ||
              SubmitData.CategoryMethods.Code ==
                Enum.Method_Receive.WalletToBank ||
              SubmitData.CategoryMethods.Code == Enum.Method_Receive.GPayToBank
            "
          >
            <ValidationProvider
              :rules="{ required: true }"
              :name="viralBankRev"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                :label="viralBankRev"
              >
                <template #label>
                  {{ viralBankRev }} <span class="has-text-danger">*</span>
                </template>
                <v-select
                  placeholder="Chọn"
                  class="style-chooser-custom custom-ip"
                  label="Value"
                  v-model="SubmitData.FundReceiveDetail.BankCode"
                  :options="listBank"
                  :reduce="(option) => option.Code"
                  @input="ChangeBankReceive"
                  :disabled="isVisibleTo"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
                <!-- <b-select
                  placeholder="Chọn"
                  expanded
                  v-model="SubmitData.FundReceiveDetail.BankCode"
                  @input="ChangeBankReceive()"
                  :disabled="isVisibleTo"
                >
                  <option
                    v-for="item in listBank"
                    :value="item.Code"
                    :key="item.Id"
                  >
                    {{ item.Value }}
                  </option>
                </b-select> -->
              </b-field>
            </ValidationProvider>
          </div>
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Nội dung"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Nội dung <span class="has-text-danger">*</span>
                </template>
                <b-input
                  v-model="SubmitData.Note"
                  placeholder="Nội dung"
                  type="textarea"
                  :disabled="isVisibleTo"
                  class="custom-ip"
                >
                </b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <div class="mt-3">
          <b-field>
            <template #label> Ảnh chứng từ </template>
            <div class="upload-field custom-bt-upload">
              <b-upload
                @input="uploadFiles"
                v-model="files"
                multiple
                accept=".png, .jpg, .jpeg"
              >
                <span
                  class="upload-file-title"
                  :class="{ 'upload-file-title-active': true }"
                  >Chọn ảnh chứng từ</span
                >
                <div class="button-upload">
                  <img src="@/assets/img/upload.svg" />
                </div>
              </b-upload>
            </div>
          </b-field>
        </div>
        <div class="mt-3">
          <div class="upload-photos" v-viewer="options">
            <FileItem
              v-for="(item, index) in SubmitData.ListImage"
              :key="index"
              :file="item"
              :idx="index"
              @deleteFile="deleteFile"
              :IsShow="true"
            />
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button
          type="is-link"
          @click.prevent="CreateFund"
          :disabled="isDisplayButton"
          >CHUYỂN QUỸ</b-button
        >
      </div>
    </div>
  </section>
</template>
<script>
import Enum from "@/helpers/Enum.vue";
import UserService from "@/modules/user/services/UserService.vue";
import FileItem from "@/modules/fundtransfers/components/FileItem.vue";
import UploadAWS from "@/helpers/UploadAWS.vue";
import moment from "moment";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import store from "@/store";
import ToastHelper from "@/helpers/OpenToast.vue";
import FundService from "@/modules/common/services/FundService.vue";
import UploadStatic from "@/helpers/UploadStatic.vue";
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;
export default {
  components: {
    FileItem,
  },
  props: [
    "listMethod",
    "listBank",
    "listShop",
    "position",
    "isload",
    "Virtual",
  ],
  data() {
    return {  
      options: {
                toolbar: true,
                url: 'data-source',
            },
      SearchData: {
        TotalFund: 0,
      },
      isDisplayButton: false,
      TypeTransactions: [],
      IsActive: false,
      DisplayNone: true,
      IsLoading: false,
      SubmitData: {
        Code: null,
        PaymentType: 0,
        CategoryMethods: {
          Id: 0,
          Code: "01",
        },
        Total: 0,
        Note: null,
        ShortNameShop: null,
        Ou: null,
        Balance: 0,
        ListImage: [],
        FundTransferDetail: {
          ShopCode: null,
          ShopName: null,
          ShopAddress: null,
          CodePrint: null,
          FullName: null,
          UserName: null,
          BankCode: null,
          BankName: null,
          Type: 1,
          Shortname: null,
          Email: null,
          AccountVirtual: null,
          EmployeeCode: null,
          BusinessOfficeCode: null,
        },
        FundReceiveDetail: {
          ShopCode: null,
          ShopName: null,
          ShopAddress: null,
          CodePrint: null,
          FullName: null,
          UserName: null,
          BankCode: null,
          BankName: null,
          Type: 2,
          ShortName: null,
          Email: null,
          AccountVirtual: null,
          EmployeeCode: null,
          BusinessOfficeCode: null,
        },
      },
      Enum: Enum,
      OuType: Enum.TRANSFER_TYPE.SameOU,
      TypeTransactionCode: null,
      filUser: {
        FullName: null,
        EmployeeCode: null,
        ShopCode: "7702",
      },
      lstUser: [],
      files: [],
      filterBalance: {
        staffCode: null,
        officeCode: null,
        currencyCode: "VND",
        paymentType: 1,
        receiveMethod: "",
      },
      isDisabledTo: true,
      isVisibleTo: false,
      valEmit: {
        position: null,
        OU: null,
      },
      listPGD: [],
      NameMethod: null,
      curentShop: null,
      HO: "121",
      HoShortName: "KTTT",
      CodeCurentShop: null,
      positions: null,
      viralFund: "Quỹ tiền mặt",
      EmpoyeeCode: null,
      viralBankTran: "Ngân hàng chuyển",
      viralBankBalance: "Số dư ngân hàng chuyển",
      viralBankRev: "Ngân hàng nhận",
    };
  },
  mounted() {},
  methods: {
    async SetDefaultData() {
      let _self = this;
      let res = store.state.user.Profile;
      _self.EmpoyeeCode = null;
      _self.SubmitData.FundTransferDetail.UserName = res.UserName;
      _self.SubmitData.FundTransferDetail.FullName = res.FullName;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let shopCurrent = _self.listShop.filter(
        (x) => x.Code == shopSelected.Code
      );
      console.log(shopCurrent);
      if (shopCurrent != null) {
        shopSelected = shopCurrent[0];
        console.log("shopSelected2", shopSelected);
        _self.SubmitData.FundReceiveDetail.BusinessOfficeCode =
          shopSelected.BusinessOfficeCode;
        _self.SubmitData.FundTransferDetail.BusinessOfficeCode =
          shopSelected.BusinessOfficeCode;
      }
      _self.SubmitData.FundTransferDetail.ShopCode = shopSelected.Code;
      _self.filterBalance.staffCode = res.EmployeeCode;
      _self.filterBalance.officeCode = shopSelected.BusinessOfficeCode;
      console.log("shopSelected", shopSelected);
      if (shopSelected.BusinessOfficeCode != Enum.OFFICE.HO)
        _self.SubmitData.FundTransferDetail.AccountVirtual = _self.Virtual;
      _self.SubmitData.FundTransferDetail.ShopName = shopSelected.Name;
      _self.SubmitData.FundTransferDetail.ShopCode = shopSelected.Code;
      _self.SubmitData.FundReceiveDetail.ShopCode = shopSelected.Code;
      _self.SubmitData.FundReceiveDetail.ShopName = shopSelected.Name;
      _self.SubmitData.FundReceiveDetail.ShopAddress = shopSelected.Address;
      _self.SubmitData.FundTransferDetail.ShopAddress = shopSelected.Address;
      _self.SubmitData.FundReceiveDetail.ShortName = shopSelected.ShortName;
      _self.SubmitData.FundTransferDetail.ShortName = shopSelected.ShortName;
      _self.SubmitData.FundTransferDetail.Email = res.Email;
      _self.SubmitData.ShortNameShop = shopSelected.ShortName;
      _self.CodeCurentShop = shopSelected.BusinessOfficeCode;
      _self.curentShop = shopSelected.ShortName;
      _self.filUser.ShopCode = _self.curentShop;

      _self.SubmitData.FundTransferDetail.EmployeeCode = res.EmployeeCode;

      //_self.listShop.filter((x)=>x.Code==shopSelected.Code)[0].ShortName;
    },
    SetActive(value) {
      let _self = this;
      _self.$parent.isLoading = true;     
      _self.ResetData();
      //_self.SetDefaultData();
      _self.OuType = Enum.TRANSFER_TYPE.SameOU;
      _self.SubmitData.Ou = Enum.TRANSFER_TYPE.SameOU;
      _self.listPGD = _self.listShop;
      _self.IsActive = value;
      _self.DisplayNone = !value;
      _self.Virtual = _self.$parent.Virtual;
      _self.SubmitData.Ou = _self.OuType;
      _self.valEmit.position = _self.position;
      _self.valEmit.OU = _self.OuType;
      if (value) _self.GetBalanceByUser();
      if (_self.SubmitData.FundReceiveDetail.ShopCode != null) {
        _self.GetEmpoyee();
      }
      _self.$parent.isLoading = false;
    },
    ChangePaymentType() {
      let _self = this;
      _self.$parent.isLoading = true;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.SubmitData.Ou = _self.OuType;
      _self.isDisabledTo = false;
      _self.$emit("ChangeTypeOU", _self.OuType);
      _self.SubmitData.Ou = _self.OuType;
      _self.listPGD = _self.listPGD.filter(
        (x) => x.Code != _self.SubmitData.FundReceiveDetail.ShopCode
      );
      _self.SubmitData.FundReceiveDetail.ShopCode = null;
      _self.SubmitData.CategoryMethods.Code = null;
      _self.viralFund = "Quỹ tiền mặt";
      //_self.SubmitData.FundReceiveDetail.ShopCode=_self.HO;
      if (_self.OuType == Enum.TRANSFER_TYPE.SameOU) {
        _self.listPGD = _self.listShop;
        _self.SubmitData.FundReceiveDetail.ShopCode = shopSelected.Code;
        _self.SubmitData.FundReceiveDetail.ShopName = shopSelected.Name;
        _self.SubmitData.FundReceiveDetail.ShopAddress = shopSelected.Address;
        _self.SubmitData.FundReceiveDetail.ShortName = shopSelected.ShortNameV2;
        _self.SubmitData.FundReceiveDetail.BusinessOfficeCode = shopSelected.BusinessOfficeCode;
        _self.isDisabledTo = true;
        _self.filUser.ShopCode = _self.curentShop;
        _self.GetEmpoyee();
      }

      _self.$parent.isLoading = false;
    },
    async GetEmpoyee() {
      let _self = this;
      //let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let result = await UserService.Get_ListEmployeePGD(
        _self.filUser.ShopCode
      );
      _self.lstUser = result;
    },
    async ChangeShop(value) {
      let _self = this;
      _self.$parent.isLoading = true;
      let selectedTypeTransaction = _self.listShop.filter(
        (x) => x.Code == value
      )[0];
      _self.SubmitData.FundReceiveDetail.ShopName =
        selectedTypeTransaction.Name;
      _self.filUser.ShopCode = selectedTypeTransaction.Code; //Sửa lại
      _self.SubmitData.FundReceiveDetail.ShopAddress =
        selectedTypeTransaction.Address;
      _self.SubmitData.FundReceiveDetail.ShortName =
        selectedTypeTransaction.ShortName;
      _self.SubmitData.FundReceiveDetail.BusinessOfficeCode =
        selectedTypeTransaction.BusinessOfficeCode;
      _self.GetEmpoyee();
      _self.$parent.isLoading = false;
    },
    async uploadFiles() {
      var _self = this;
      _self.$parent.isLoading = true;
      for (let i = 0; i < this.files.length; i++) {
        let item = this.files[i];
        let now = moment();
        let folder = "Images/Transfer";
        let pathRoot = `${now.format("YYYY")}/${now.format("MM")}/${folder}`;
        let fileName = `${now.format("YYYYMMDD")}_${now.format(
          "HHmmss"
        )}_${now.format("X")}_${Math.floor(Math.random() * 0x10000).toString(
          16
        )}`;
        let pathFull = `${pathRoot}/${fileName}.${item.name.slice(
          item.name.lastIndexOf(".") + 1
        )}`;

        await UploadStatic.PutFileS3(
          pathFull,
          item,
          fileName,
        );

        // if (result) {
          let file = {
            Path: amzUrl + pathFull,
            Name: item.name,
            Type: 1,
          };
          _self.SubmitData.ListImage.push(file);
        // }
      }
      _self.files = [];
      _self.$parent.isLoading = false;
    },
    deleteFile(file) {
      var _self = this;

      _self.$buefy.dialog.confirm({
        title: "Xóa ảnh",
        message: Enum.MESSAGE.ConfirmDeleteImage + file.Name + "</b>",
        confirmText: "Đồng ý",
        cancelText: "Hủy",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          _self.$parent.isLoading = true;
          var currentItem = _self.SubmitData.ListImage.filter(
            (x) => x.Path == file.Path
          )[0];
          var index = _self.SubmitData.ListImage.indexOf(currentItem);
          if (index > -1) {
            await UploadAWS.deleteFile(file.Path);
            _self.SubmitData.ListImage.splice(index, 1);
            _self.$parent.isLoading = false;
          }
        },
      });
    },
    async CreateFund() {
      var _self = this;

      let isValid = await this.validateData();
      if (!isValid) return;

      if (_self.isDisplayButton) return;
      _self.isDisplayButton = true;
      setTimeout(async () => {
        try {
          if (
            parseFloat(_self.SubmitData.Total) >
            parseFloat(_self.SearchData.TotalFund)
          ) {
            ToastHelper.OpenToast(Enum.MESSAGE.MoneyOver, "is-warning");
            _self.isDisplayButton = false;
            return;
          }
          if (
            _self.OuType == Enum.TRANSFER_TYPE.SameOU &&
            _self.SubmitData.CategoryMethods.Code !==
              Enum.Method_Receive.CashToBank &&
            _self.SubmitData.CategoryMethods.Code !==
              Enum.Method_Receive.BankToBank
          ) {
            if (
              _self.SubmitData.FundReceiveDetail.UserName ===
              _self.SubmitData.FundTransferDetail.UserName
            ) {
              ToastHelper.OpenToast(Enum.MESSAGE.TranYourSelf, "is-warning");
              _self.isDisplayButton = false;
              return;
            }
          }

          if (_self.SubmitData.Total === 0) {
            ToastHelper.OpenToast(Enum.MESSAGE.MoneyZero, "is-warning");
            _self.isDisplayButton = false;
            return;
          }
          _self.$parent.isLoading = true;
          _self.SubmitData.PaymentType = _self.filterBalance.paymentType;
          var result = await FundTransferService.Post_CreateFundTransfer(
            _self.SubmitData
          );

          _self.positions = Enum.METHOD_TYPE.PGD;
          if (
            _self.SubmitData.FundTransferDetail.BusinessOfficeCode ==
            Enum.OFFICE.HO
          ) {
            _self.positions = Enum.METHOD_TYPE.HO;
          }
          //  _self.SubmitData.FundTransferDetail.ShopCode=ShopTranfer.BusinessOfficeCode;
          // _self.SubmitData.FundReceiveDetail.ShopCode=ShopReceive.BusinessOfficeCode;

          if (result) {
            //Call sang Mifos
            var request = {
              amount: _self.SubmitData.Total.toString(),
              note: _self.SubmitData.Note,
              referenceSourceId: "4", // Mã kênh thực hiện (0: Mifos 1: Los 2: App 3: Bao hiem )
              referenceTransactionType: Enum.TRANTYPEMIFOS.Orther.Tran,
              paymentType: Enum.PAYMENT_TYPE.Cash, // Tiền mặt
              transationType: Enum.TRANSACTIONTYPEMIFOS.Tran, // 1: Thu 2: Chi
              office: _self.SubmitData.FundTransferDetail.BusinessOfficeCode,
              createdBy: store.state.user.Profile.EmployeeCode, //EmployeeCode
              userOfficeCode:
                _self.SubmitData.FundTransferDetail.BusinessOfficeCode,
              staffCode: store.state.user.Profile.EmployeeCode,
              detail: {
                officeCodeTransaction:
                  _self.SubmitData.FundReceiveDetail.BusinessOfficeCode,
                receiveMethodTransaction:
                  _self.SubmitData.FundReceiveDetail.BankName, //ngược
                receiveMethod: _self.SubmitData.FundTransferDetail.BankName, //xuôi
                receiptCode: result.CodePrintTran,
                referenceReceiptCode: result.CodePrintRe,
                businessType: Enum.BUSINESS_TYPE.CQ,
                transferType: _self.SubmitData.CategoryMethods.Name,
                transactionId: result.FundTranferTransactionCode,
                referenceTransactionId: result.FundReceiveTransactionCode,
                employeeCode: _self.EmpoyeeCode,
              },
            };
            if (_self.SubmitData.CategoryMethods.Code == "01") {
              request.detail.transferType = "TM -> TM";
            } else if (_self.SubmitData.CategoryMethods.Code == "02") {
              request.detail.transferType =
                "TM -> " + _self.SubmitData.FundReceiveDetail.BankName;
            } else if (_self.SubmitData.CategoryMethods.Code == "03") {
              request.detail.transferType =
                _self.SubmitData.FundTransferDetail.BankName +
                "->" +
                _self.SubmitData.FundReceiveDetail.BankName;
            } else if (_self.SubmitData.CategoryMethods.Code == "04") {
              request.detail.transferType =
                _self.SubmitData.FundTransferDetail.BankName + " -> TM";
            } else if (
              _self.SubmitData.CategoryMethods.Code ==
              Enum.Method_Receive.BankToWallet
            ) {
              request.detail.transferType =
                _self.SubmitData.FundTransferDetail.BankName +
                " -> Ví(" +
                _self.SubmitData.FundReceiveDetail.BankName +
                ")";
              request.referenceTransactionType = Enum.TRANTYPEMIFOS.Wallet.Tran;
            } else if (
              _self.SubmitData.CategoryMethods.Code ==
              Enum.Method_Receive.WalletToBank
            ) {
              request.referenceTransactionType = Enum.TRANTYPEMIFOS.Wallet.Tran;
              request.detail.transferType =
                "Ví(" +
                _self.SubmitData.FundTransferDetail.BankName +
                ") -> " +
                _self.SubmitData.FundReceiveDetail.BankName;
            }
            if (
              _self.SubmitData.CategoryMethods.Code ===
              Enum.Method_Receive.WalletToBank
            ) {
              request.paymentType = Enum.PAYMENT_TYPE.Wallet;
            } else if (
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.CashToCash ||
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.CashToBank
            ) {
              request.paymentType = Enum.PAYMENT_TYPE.Cash;
            } else if (
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.BankToBank ||
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.BankToCash ||
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.BankToWallet
            ) {
              request.paymentType = Enum.PAYMENT_TYPE.Bank;
            }
            //HO & Cùng OU TM -> NH
            if (
              _self.positions === Enum.METHOD_TYPE.HO &&
              _self.OuType === Enum.TRANSFER_TYPE.SameOU &&
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.CashToBank
            ) {
              request.referenceTransactionType =
                Enum.TRANTYPEMIFOS.HoCashToBank.Tran;
              request.office = Enum.OFFICE.HO;
              request.detail.officeCodeTransaction = Enum.OFFICE.HO;
            }
            ///PGD & Khác OU TM->NH
            if (
              _self.positions === Enum.METHOD_TYPE.PGD &&
              _self.OuType === Enum.TRANSFER_TYPE.DifferentOU &&
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.CashToBank
            ) {
              request.detail.officeCodeTransaction = Enum.OFFICE.HO;
            }
            ///Nếu HO & Cùng OU TM -> TM
            if (
              _self.positions === Enum.METHOD_TYPE.HO &&
              _self.OuType === Enum.TRANSFER_TYPE.SameOU &&
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.CashToCash
            ) {
              request.office = Enum.OFFICE.HO;
              request.detail.officeCodeTransaction = Enum.OFFICE.HO;
              request.referenceTransactionType =
                Enum.TRANTYPEMIFOS.HOCashToCash.Tran;
            }

            if (
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.WalletToBank ||
              _self.SubmitData.CategoryMethods.Code ===
                Enum.Method_Receive.BankToWallet
            ) {
              request.referenceTransactionType = Enum.TRANTYPEMIFOS.Wallet.Tran;
            }
            if (
              _self.SubmitData.CategoryMethods.Code ===
              Enum.Method_Receive.GPayToBank
            ) {
              request.referenceTransactionType = Enum.TRANTYPEMIFOS.GPay.Tran;
              request.paymentType = Enum.PAYMENT_TYPE.GPay;
              request.detail.transferType =
                "GPay-CIMB -> " + _self.SubmitData.FundReceiveDetail.BankName;
            }
            let success;
            let tranRequest = {
              FundId: result.Id,
              IsSucess: false,
              Request: JSON.stringify(request),
              FundType: Enum.FUNDDETAILTYPE.TRAN,
            };
            await FundTransferService.Post_UpdateStatusMifos(tranRequest);

            success = await FundService.Post_CreateTransactionFund(
              result.Id,
              1,
              store.state.user.Profile.FullName,
              request
            );

            if (success) {
              ToastHelper.OpenToast(Enum.MESSAGE.Ok_FundInsert, "is-success");
              let tranRequest = {
                FundId: result.Id,
                IsSucess: success,
                Request: JSON.stringify(request),
                FundType: Enum.FUNDDETAILTYPE.TRAN,
              };
              await FundTransferService.Post_UpdateStatusMifos(tranRequest);
              _self.SetActive(false);
              _self.ResetData();
            } else {
              ToastHelper.OpenToast(Enum.MESSAGE.Mifos_Error, "is-danger");
              let tranRequest = {
                FundId: result.Id,
                IsSucess: success,
                Request: JSON.stringify(request),
                FundType: Enum.FUNDDETAILTYPE.TRAN,
              };
              await FundTransferService.Post_UpdateStatusMifos(tranRequest);
              _self.SetActive(false);
              _self.ResetData();
            }
            _self.$emit("AddSuccess");
          } else {
            ToastHelper.OpenToast(Enum.MESSAGE.Update_Error, "is-danger");
            _self.SetActive(false);
            _self.ResetData();
          }
          _self.$parent.isLoading = false;
        } catch (ex) {
          console.log(ex);
        }
        _self.isDisplayButton = false;
      }, 500);
    },
    async ChangeReceiveUser() {
      var _self = this;
      var selectedTypeTransaction = _self.lstUser.filter(
        (x) => x.UserName == _self.SubmitData.FundReceiveDetail.UserName
      )[0];
      _self.EmpoyeeCode = selectedTypeTransaction.EmployeeCode;
      _self.SubmitData.FundReceiveDetail.EmployeeCode = _self.EmpoyeeCode;
      _self.SubmitData.FundReceiveDetail.FullName =
        selectedTypeTransaction.FullName;
      _self.SubmitData.FundReceiveDetail.Email = selectedTypeTransaction.Email;
    },
    async ChangeBankTransfer() {
      var _self = this;
      if (
        _self.SubmitData.FundTransferDetail.BankCode == null ||
        _self.SubmitData.FundTransferDetail.BankCode == ""
      ) {
        _self.SearchData.TotalFund = 0;
        _self.SubmitData.Balance = 0;
        return;
      }
      _self.$parent.isLoading = true;

      var selectedTypeTransaction = _self.listBank.filter(
        (x) => x.Code == _self.SubmitData.FundTransferDetail.BankCode
      )[0];
      _self.SubmitData.FundTransferDetail.BankName =
        selectedTypeTransaction.Value;
      //Gán giá trị cho bank chuyển tiền lấy lại số dư quỹ
      _self.filterBalance.receiveMethod =
        _self.SubmitData.FundTransferDetail.BankName;
      _self.GetBalanceByUser();
      _self.$parent.isLoading = false;
    },
    async ChangeBankReceive() {
      var _self = this;
      var selectedTypeTransaction = _self.listBank.filter(
        (x) => x.Code == _self.SubmitData.FundReceiveDetail.BankCode
      )[0];
      _self.SubmitData.FundReceiveDetail.BankName =
        selectedTypeTransaction.Value;
    },
    async GetUserCurent() {
      return await UserService.getUserProfile();
    },
    async GetBalanceByUser() {
      var _self = this;
      _self.filterBalance.paymentType = 1;
      if (
        _self.SubmitData.CategoryMethods.Code ==
          Enum.Method_Receive.BankToBank ||
        _self.SubmitData.CategoryMethods.Code ==
          Enum.Method_Receive.BankToCash ||
        _self.SubmitData.CategoryMethods.Code ==
          Enum.Method_Receive.BankToWallet
      ) {
        _self.filterBalance.paymentType = 2;
      }
      if (
        _self.SubmitData.CategoryMethods.Code ==
        Enum.Method_Receive.WalletToBank
      ) {
        _self.filterBalance.paymentType = 4;
        _self.filterBalance.staffCode = null;
      }
      if (
        _self.SubmitData.CategoryMethods.Code == Enum.Method_Receive.GPayToBank
      ) {
        _self.filterBalance.paymentType = 5;
        _self.filterBalance.staffCode = null;
      }
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      console.log("shopSelected", shopSelected);
      _self.filterBalance.officeCode = shopSelected.BusinessOfficeCode;
      if (shopSelected.BusinessOfficeCode == Enum.OFFICE.HO) {
        _self.filterBalance.officeCode = Enum.OFFICE.HO;
      }
      //_self.filterBalance.receiveMethod=_self.listBank.filter((x)=>x.Code==_self.SubmitData.FundTransferDetail.BankCode).Value
      var result = await FundTransferService.Post_GetFundBalance(
        _self.filterBalance
      );

      _self.SearchData.TotalFund = 0;
      _self.SubmitData.Balance = 0;
      if (result != null && result.balance > 0) {
        _self.SearchData.TotalFund = parseFloat(result.balance);
        _self.SubmitData.Balance = parseFloat(result.balance);
      }
    },
    async ChangeMethod() {
      var _self = this;
      _self.filterBalance.receiveMethod = "";
      _self.SearchData.TotalFund = 0;
      let selected = _self.listMethod.filter(
        (x) => x.Code == _self.SubmitData.CategoryMethods.Code
      );
      _self.SubmitData.FundReceiveDetail.BankCode = null;
      _self.SubmitData.FundTransferDetail.BankName = null;
      _self.SubmitData.FundReceiveDetail.BankName = null;
      _self.SubmitData.FundTransferDetail.BankCode = null;
      _self.filterBalance.paymentType = 1;
      _self.NameMethod = selected.Name;
      if (
        _self.SubmitData.CategoryMethods.Code ==
          Enum.Method_Receive.CashToCash ||
        _self.SubmitData.CategoryMethods.Code == Enum.Method_Receive.CashToBank
      ) {
        _self.filterBalance.paymentType = 1;
        _self.viralFund = "Quỹ tiền mặt";
        _self.GetBalanceByUser();
      } else if (
        _self.SubmitData.CategoryMethods.Code ==
        Enum.Method_Receive.WalletToBank
      ) {
        _self.filterBalance.paymentType = 4;
        _self.viralFund = "Quỹ ví";
        _self.viralBankTran = "Ví chuyển";
        _self.viralBankBalance = "Số dư ví chuyển";
        _self.viralBankRev = "Ngân hàng nhận";
      } else if (
        _self.SubmitData.CategoryMethods.Code ==
        Enum.Method_Receive.BankToWallet
      ) {
        _self.viralFund = "Quỹ ngân hàng";
        _self.viralBankTran = "Ngân hàng chuyển";
        _self.viralBankBalance = "Số dư ngân hàng chuyển";
        _self.viralBankRev = "Ví nhận";
      } else if (
        _self.SubmitData.CategoryMethods.Code == Enum.Method_Receive.GPayToBank
      ) {
        _self.filterBalance.paymentType = 5;
        _self.viralFund = "Quỹ GPay-CIMB";
        _self.viralBankTran = "GPay-CIMB";
        _self.viralBankBalance = "Số dư Gpay-CIMB";
        _self.viralBankRev = "Ngân hàng nhận";
        _self.SubmitData.paymentType = 5;
      } else {
        _self.viralFund = "Quỹ ngân hàng";
        _self.viralBankTran = "Ngân hàng chuyển";
        _self.viralBankBalance = "Số dư ngân hàng chuyển";
      }
      //_self.ChangePaymentType();
      _self.isDisabledTo = false;

      if (
        _self.OuType == Enum.TRANSFER_TYPE.DifferentOU &&
        _self.CodeCurentShop != Enum.OFFICE.HO &&
        _self.SubmitData.CategoryMethods.Code == Enum.Method_Receive.CashToBank
      ) {
        var infor = _self.listShop.filter((x) => x.Code == _self.HO)[0];
        console.log(infor);
        _self.SubmitData.FundReceiveDetail.ShopCode = _self.HO;
        _self.SubmitData.FundReceiveDetail.ShopAddress = infor.Address;
        _self.SubmitData.FundReceiveDetail.ShopName = infor.Name;
        _self.SubmitData.FundReceiveDetail.Shortname = infor.ShortName;
        _self.filUser.ShopCode = infor.ShortName;
        _self.SubmitData.FundReceiveDetail.BankCode = "33";
        _self.SubmitData.FundReceiveDetail.BusinessOfficeCode =
          infor.BusinessOfficeCode;
        _self.SubmitData.FundReceiveDetail.BankName = _self.listBank.filter(
          (x) => x.Code == "33"
        )[0].Value;
        _self.isDisabledTo = true;
        _self.GetEmpoyee();
      }
      if (_self.OuType == Enum.TRANSFER_TYPE.SameOU) _self.isDisabledTo = true;
      //
      console.log("_self.SubmitData.FundReceiveDetail.ShopCode 2",_self.SubmitData.FundReceiveDetail.ShopCode );
    },
    async validateData() {
      return await this.$refs.observer.validate().then(async (result) => {
        if (!result) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter((error) => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);

          return false;
        }

        return true;
      });
    },
    async ResetData() {
      var _self = this;
      _self.SubmitData = {
        Code: null,
        CategoryMethods: {
          Id: 0,
          Code: "01",
        },
        Total: 0,
        Note: null,
        ShortNameShop: null,
        Ou: null,
        Balance: 0,
        ListImage: [],
        FundTransferDetail: {
          ShopCode: null,
          ShopName: null,
          ShopAddress: null,
          CodePrint: null,
          FullName: null,
          UserName: null,
          BankCode: null,
          BankName: null,
          Type: 1,
          ShortName: null,
          BusinessOfficeCode: null,
        },
        FundReceiveDetail: {
          ShopCode: null,
          ShopName: null,
          ShopAddress: null,
          CodePrint: null,
          FullName: null,
          UserName: null,
          BankCode: null,
          BankName: null,
          Type: 2,
          ShortName: null,
          BusinessOfficeCode: null,
        },
      };
      _self.filterBalance.paymentType = 1;
       _self.SetDefaultData();
      // _self.GetBalanceByUser();
    },
  },
};
</script>
<style scoped lang="scss">
</style>
